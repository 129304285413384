import React from "react"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Clock, Calendar, Edit } from "react-feather"

import Layout from "../common/layout/Layout"
import { Boxed } from "../common/layout/Containers"
import Share from "../forms/Share"
import Posts from "../sections/Posts"
import Title from "../ui/Title"
import SubscribeForm from "../sections/SubscribeForm"
import Seo from "../common/SEO"

const PostTemplate = ({ data, rootPath, locationPathname }) => {
  const {
    post: {
      title,
      body: { body: content },
      heroImage,
      author: { name: author },
      publishDate: date,
      // categories,
      readTime,
      description: { description },
    },
    relatedPosts: { nodes: relatedPosts },
  } = data

  const image = getImage(heroImage)
  const seoImage = image
    ? {
        width: image.width,
        height: image.height,
        src: image.images.fallback.src,
      }
    : null

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={seoImage}
        pathname={locationPathname}
      />
      <section>
        <Boxed fixedWidth>
          <div style={{ padding: "3rem 0", fontSize: "1.2rem" }}>
            <article
              style={{
                marginBottom: `${relatedPosts.length > 0 ? "0" : "6rem"}`,
              }}
            >
              <PostHeader>
                <header>
                  <h1>{title}</h1>
                </header>
                <PostSignature>
                  <p>
                    <span>
                      <Edit size={15} /> {author}{" "}
                    </span>
                    <span style={{ fontSize: "1rem" }} className="time">
                      <span>
                        <Calendar size={15} /> {date}
                      </span>
                      <span style={{ marginLeft: "1rem" }}>
                        <Clock size={15} /> {`${readTime} minutes de lecture`}
                      </span>
                    </span>
                  </p>
                </PostSignature>
                <Share url={locationPathname} />
                {/* <BlogCategories
                  categories={categories}
                  style={{ margin: "0" }}
                  rootPath={rootPath}
                /> */}
              </PostHeader>
              <GatsbyImage
                image={image}
                alt={title}
                style={{ overflow: "initial" }}
              />
              <PostContent>
                <Markdown children={content} linkTarget="_blank" />
              </PostContent>
              <PostFooter>
                <span>{author}</span>
              </PostFooter>
              <Share url={locationPathname} />
            </article>
          </div>
        </Boxed>
      </section>
      {relatedPosts.length > 0 && (
        <section>
          <Boxed>
            <TitleStyled>
              <h2>À lire aussi</h2>
            </TitleStyled>
            <Posts posts={relatedPosts} rootUrl={rootPath} fit minimal />
          </Boxed>
        </section>
      )}
      <SubscribeForm bgLight />
    </Layout>
  )
}

export default PostTemplate

const Markdown = styled(ReactMarkdown)`
  list-style: unset;
  a {
    color: var(--clr-primary-8);
    text-decoration: underline;
    :hover {
      color: var(--clr-primary-5);
    }
  }
  p {
    img {
      width: 25rem;
    }
  }
`

const PostHeader = styled.div`
  margin-bottom: 2rem;
  header {
    margin-bottom: 1.5rem;
  }
  h1 {
    font-weight: 600;
  }
`

const PostFooter = styled.div`
  margin-top: 4rem;
  margin-bottom: 2rem;
  span {
    border-top: 2px solid var(--clr-primary-5);
    padding: 10px;
  }
`

const PostContent = styled.div`
  margin: 2rem 0;
  p {
    line-height: 1.7;
  }
  img {
    margin: 3rem 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  blockquote {
    margin: 0 0 1.5em;
    padding: 0 1.5em;
    border-left: 3px solid var(--clr-primary-9);
    p {
      margin-bottom: 0;
      padding: 10px;
    }
  }
  h2,
  h3 {
    font-weight: 700;
  }
  h3 {
    font-size: 34px;
    font-family: "HK Grotesk Normal";
  }
`

const PostSignature = styled.div`
  span {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.2rem;
    }
  }
  .time {
    color: var(--clr-grey-5);
  }
`

const TitleStyled = styled(Title)`
  @media (max-width: 640px) {
    text-align: left;
    .underline {
      display: none;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 2rem;
  }
`
