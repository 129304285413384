import React from "react";
import styled from "styled-components";

const Title = ({ children, className }) => {
  return (
    <SectionTitle className={className}>
      {children}
      <div className="underline"></div>
    </SectionTitle>
  );
};

const SectionTitle = styled.div`
  margin-bottom: 4rem;
  text-align: center;

  .underline {
    width: 5rem;
    height: 0.25rem;
    margin-bottom: 1.25rem;
    background: var(--clr-primary-5);
    margin-left: auto;
    margin-right: auto;
  }
`;

export default Title;
